import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={4}>
      <Hero offset={0}>
        <BigTitle>
          株式会社<br/>まなびのデザイン
        </BigTitle>
        <Subtitle>
          2019年度始動準備中
        </Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>サービス紹介</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="医師国家試験の取扱説明書"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            これまでメールマガジンとして配信して来ましたが、昨今の学生の使用するツールの変化に伴い、配信媒体の再検討を行いこれまでとは違う形での配信を計画しています
          </ProjectCard>
          <ProjectCard
            title="国試対策個別指導"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            ・第114回受験生が対象<br />
            ・現役/浪人問わず<br />
            ・受講料は大手予備校の個別指導と同等<br />
            ・東京を拠点とするが、主要都市での開催は要相談<br />
            ・定員 5名<br />
            ・指導方針：羊土社「医師国家試験の取扱説明書」準拠<br />
            ・コーチングの手法を用いて、対話形式での指導
          </ProjectCard>
          <ProjectCard
            title="国試オンライン勉強会"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            ・第114回受験生が対象だが、M4,5も受講可能<br />
            ・現役/浪人問わず<br />
            ・月に1回程度の配信(年10回を予定)<br />
            ・実臨床リアリティ  赤本 第3章の内容を中心に<br />
            ・合格ストラテジー  赤本 第1,2章の内容を中心に<br />
            ・指導方針：羊土社「医師国家試験の取扱説明書」準拠<br />
            ・受講料：各コース 月額2,000円 予定<br />
            ・第113回の医師国家試験過去問を中心に取り上げます<br />
            ・赤本を用いて、医師国家試験問題の解説を行います<br />
          </ProjectCard>
          <ProjectCard
            title="研修医向けオンライン講座"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
          『輸液』 『抗菌薬』『外傷初療』『血液ガス分析』『ERの作法』『カルテ指導』というコンテンツに絞っての配信を予定しています。2019夏以降配信予定。
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <Contact offset={3}>
        <Inner>
          <Title>お問い合わせ、個別指導等の相談</Title>
          <ContactText>
            お問い合わせは<a href="mailto:info@manabino-design.com">こちらにメール</a>、<br />あるいはSNSでご連絡ください:{' '}
            <a href="https://twitter.com/md_kokushi">Twitter</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2019 by Gatsby Starter Portfolio Cara.{' '}
          <a href="https://github.com/LekoArts/gatsby-starter-portfolio-cara">Github Repository</a>. Made by{' '}
          <a href="https://www.lekoarts.de">LekoArts</a>.
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
